import React, { useState, useEffect } from 'react'
import { Link, graphql, PageProps } from 'gatsby'

import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs'
import Section from '../components/section/section'
import SectionHeadline from '../components/section/sectionHeadline'
import ToolTile from '../components/toolTile/toolTile'
import ToolTileGrid from '../components/toolTile/toolTileGrid'
import { trackEvent } from '../utils/gtag'
import { mapMethod } from '../utils/mapMethodQueryResults'

import Icon404 from '../assets/images/icon404.inline.svg'
import './404.sass'

const quotes = [
  {
    quote:
      'Design is not just what it looks like and feels like. Design is how it works.',
    author: 'Steve Jobs',
  },
  {
    quote:
      'A user interface is like a joke. If you have to explain it, it’s not that good.',
    author: 'Martin LeBlanc',
  },
  {
    quote:
      'Good design is actually a lot harder to notice than poor design, in part because good designs fit our needs so well that the design is invisible.',
    author: 'Don Norman',
  },
  {
    quote: 'The details are not the details. They make the design.',
    author: 'Charles Eames',
  },
  {
    quote: 'A problem well stated is a problem half solved.',
    author: 'Charles Kettering',
  },
  {
    quote: 'The details are not the details. They make the design.',
    author: 'Charles Eames',
  },
  {
    quote:
      'If you think good design is expensive, you should look at the cost of bad design.',
    author: 'Ralf Speth',
  },
  {
    quote: 'You cannot understand good design if you do not understand people.',
    author: 'Dieter Rams',
  },
  {
    quote: "Design is so simple, that's why it's so complicated.",
    author: 'Paul Rand',
  },
  {
    quote: "Life's too short to build something noone wants.",
    author: 'Ash Maurya',
  },
  {
    quote:
      'Vision starts with a firm foundation and carries through with design principles.',
    author: 'Lexi Thorn and Scott Maywood-Bryant',
  },
  {
    quote:
      'When something exceeds your ability to understand how it works, it sort of becomes magical.',
    author: 'Jonathan Ive',
  },
]

const mockBreadcrumbs = [
  { title: 'UX Strategy Kit', href: '/' },
  { title: 'Page not found', href: '/' },
]

const NotFoundPage: React.FC<PageProps<
  Queries.NotFoundPageQuery,
  Queries.NotFoundPageQueryVariables
>> = ({ location: { href }, data }) => {
  if (!data.directus.not_found) {
    return null
  }

  const { popular } = data.directus.not_found
  const [quote, setQuote] = useState<{
    quote: string
    author: string
  }>()

  useEffect(() => {
    const index = Math.floor(Math.random() * quotes.length)
    setQuote(quotes[index])
  }, [])
  useEffect(() => {
    trackEvent('404', href)
  }, [])

  return (
    <DefaultLayout
      hasBanner={false}
      seo={{ title: '404' }}
      className="error-page"
    >
      <Section className="breadcrumbs-section">
        <Breadcrumbs breadcrumbs={mockBreadcrumbs} />
      </Section>
      <Section className="error-page__section">
        <div className="error-page__content">
          <h1 className="error-page__title">
            404! The page you’re looking for does not exist.
          </h1>
          {quote && (
            <blockquote className="error-page__quote">
              <p className="error-page__quote-content">{quote.quote}</p>
              <footer className="error-page__quote-author">
                — {quote.author}
              </footer>
            </blockquote>
          )}
          <Link to="/" className="error-page__backlink">
            Back to the Start
          </Link>
        </div>
      </Section>
      <Icon404 className="error-page__icon" />
      {popular?.methods && popular.methods.length > 0 && (
        <Section className="featured-tools-section">
          <header className="section-header section-header--flex">
            <SectionHeadline className="section-header__headline">
              Overall most popular methods
            </SectionHeadline>
          </header>
          <ToolTileGrid className="templates__grid">
            {popular.methods.map(methodItem => {
              if (!methodItem?.method_id) {
                return null
              }

              const method: Method = mapMethod(methodItem.method_id)

              return (
                <ToolTile
                  category={method.categories[0]}
                  duration={method.duration}
                  iconUrl={method.thumb}
                  key={method.slug}
                  link={`/methods/${method.slug}/`}
                  participants={method.participants}
                  summary={method.headline}
                  tags={method.tags}
                  title={method.name}
                />
              )
            })}
          </ToolTileGrid>
        </Section>
      )}
    </DefaultLayout>
  )
}

export default NotFoundPage

export const query = graphql`
  query NotFoundPage {
    directus {
      not_found {
        popular {
          name
          methods {
            method_id {
              ...methodFragment
            }
          }
        }
      }
    }
  }
`
